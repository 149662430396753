<template>
  <v-container>
    <h2 class="headtitle">สรุปจำนวนเบาะแสยาเสพติด</h2>
    <v-row v-if="opendiv199">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          @change="onChangeArea()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          @change="onChangeProvince()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="1" sm="6" xs="12">
        <v-row align="center" justify="center"
          ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
            >เคลียร์</v-btn
          ></v-row
        >
      </v-col>
    </v-row>
    <v-row v-if="opendiv2">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          @change="onChangeProvince()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="1" sm="6" xs="12">
        <v-row align="center" justify="center"
          ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
            >เคลียร์</v-btn
          ></v-row
        >
      </v-col>
    </v-row>
    <v-row v-if="opendiv3">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="1" sm="6" xs="12">
        <v-row align="center" justify="center"
          ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
            >เคลียร์</v-btn
          ></v-row
        >
      </v-col>
    </v-row>
    <v-row v-if="opendiv4">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
          disabled
        ></v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" md="1" sm="6" xs="12">
          <v-row align="center" justify="center"
            ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
              >เคลียร์</v-btn
            ></v-row
          >
        </v-col> -->
    </v-row>
    <v-col cols="12" md="12" sm="12" xs="12" v-if="checklogin.userType !== '4'">
      <v-row align="center" justify="center">
        <v-btn
          width="80px"
          class="mt-2 mb-4"
          @click="getReportDrugclues()"
          color="#833133"
          dark
          >ค้นหา</v-btn
        >
      </v-row>
    </v-col>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4" sm="12" class="mr-4">
        <v-text-field
          class="mb-4"
          v-model="search"
          dense
          hide-details
          outlined
          placeholder="ค้นหา สภ./จังหวัด/ภาค"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="4" sm="12" class="mr-8 mb-4"
        ><ExportDrugClues :list1="list1"
      /></v-col>
    </v-row>
    <!-- <v-row alig="center" justify="center">
      <v-col cols="12" md="4" sm="12" class="mr-8 mb-4">
        
      </v-col>
    </v-row> -->
    <ExportAllDrugClues :list2="list2" />
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="list1"
          :search="search"
          :items-per-page="10"
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.statusDone`]="{ item }">
            <span v-if="item.statusDone == null">0</span>
            <span v-else>{{ item.statusDone }}</span>
          </template>
          <template v-slot:[`item.statusWaiting`]="{ item }">
            <span v-if="item.statusWaiting == null">0</span>
            <span v-else>{{ item.statusWaiting }}</span>
          </template>
          <template v-slot:[`item.percent`]="{ item }">
            <span
              >{{ ((item.statusDone * 100) / item.sumAmt).toFixed(2) }}%</span
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Decode, Encode } from "@/services";
import ExportDrugClues from "@/views/ExportExcel/ExportDrugClues";
import ExportAllDrugClues from "@/views/ExportExcel/ExportAllDrugClues";
export default {
  components: { ExportDrugClues, ExportAllDrugClues },
  data() {
    return {
      search: "",
      list1: [],
      list2: [],
      count: 0,
      headers: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อสถานี", value: "station", align: "center" },
        { text: "จังหวัด", value: "province", align: "center" },
        { text: "ภาค", value: "area", align: "center" },
        { text: "ดำเนินการแล้ว", value: "statusDone", align: "center" },
        { text: "ยังไม่ได้ดำเนินการ", value: "statusWaiting", align: "center" },
        { text: "จำนวนทั้งหมด", value: "sumAmt", align: "center" },
        { text: "จับกุม", value: "status4", align: "center" },
        { text: "รายงานตัว/หยุดพฤิตการณ์", value: "status5", align: "center" },
        { text: "พิสูจน์ทราบไม่ได้", value: "status6", align: "center" },
        { text: "พบพฤติการณ์", value: "status7", align: "center" },
        { text: "หลบหนีออกนอกพื้นที่", value: "status8", align: "center" },
        { text: "อยู่ระหว่างสืบสวนติดตาม", value: "status9", align: "center" },
        { text: "ไม่พบพฤติการณ์", value: "status10", align: "center" },
        { text: "อื่นๆ", value: "status11", align: "center" },
        { text: "ในพื้นที่", value: "thisArea", align: "center" },
        { text: "นอกในพื้นที่", value: "notThisArea", align: "center" },
        { text: "ยาบ้า", value: "drugBha", align: "center" },
        { text: "เฮโรอีน", value: "drugHeloine", align: "center" },
        { text: "ยาไอซ์", value: "drugIce", align: "center" },
        { text: "ยาอี", value: "drugE", align: "center" },
        { text: "กัญชา", value: "drugKancha", align: "center" },
        { text: "ยาเค", value: "drugK", align: "center" },
        { text: "กระท่อม", value: "drugKatom", align: "center" },
        { text: "ยาอื่นๆ", value: "drugOther", align: "center" },
      ],
      opendiv199: false,
      opendiv2: false,
      opendiv3: false,
      opendiv4: false,
      items: [],
      items2: [
        { text: "ทั่วประเทศ", group: "" },
        { text: "นครบาล", group: "น." },
        { text: "ภาค1", group: "ภ.1" },
        { text: "ภาค2", group: "ภ.2" },
        { text: "ภาค3", group: "ภ.3" },
        { text: "ภาค4", group: "ภ.4" },
        { text: "ภาค5", group: "ภ.5" },
        { text: "ภาค6", group: "ภ.6" },
        { text: "ภาค7", group: "ภ.7" },
        { text: "ภาค8", group: "ภ.8" },
        { text: "ภาค9", group: "ภ.9" },
      ],
      items3: [],
      items4: [],
      data: {
        area: "",
        province: "",
        station: "",
      },
      checklogin: "",
    };
  },
  created() {
    this.getUserDrugclues();
    this.getReportDrugclues();
    this.getAllReportDrugclues();
  },
  methods: {
    clear() {
      this.data.area = "";
      this.data.province = "";
      this.data.station = "";
      this.getUserDrugclues();
      this.getReportDrugclues();
    },
    async onChangeArea() {
      this.items3 = [];
      this.items4 = [];
      this.data.province = "";
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?group=` + this.data.area
      );
      this.items3 = response.data.data;

      // this.items4 = response.data.data;
    },
    async onChangeProvince() {
      this.items4 = [];
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?province=` +
          this.data.province
      );
      this.items4 = response.data.data;

      // this.items4 = response.data.data;
    },
    async searchDropdown() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);

      this.data.area = Array.from(
        new Set(this.items.map((a) => a.group.trim()))
      ).map((group) => {
        return this.items.find((a) => a.group.trim() === group.trim());
      });
    },
    async getUserDrugclues() {
      this.list1 = [];
      this.checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
      // console.log(this.checklogin);
      // var data;

      if (this.checklogin.userType == "1" || this.checklogin.userType == "99") {
        this.opendiv199 = true;
      } else if (this.checklogin.userType == "2") {
        this.data = {
          area: this.checklogin.area || "",
          province: "",
          station: "",
        };
        this.onChangeArea();
        this.opendiv2 = true;
      } else if (this.checklogin.userType == "3") {
        this.data = {
          area: this.checklogin.area || "",
          province: this.checklogin.province || "",
          station: "",
        };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.data.area
        );
        this.items3 = response.data.data;
        this.onChangeProvince();

        console.log(this.data);
        this.opendiv3 = true;
      } else if (this.checklogin.userType == "4") {
        this.data.station = this.checklogin.station;
        console.log("this.data.station", this.data.station);
      }
    },
    async getReportDrugclues() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/drugrelatedReport?group=` +
          this.data.area +
          "&province=" +
          this.data.province +
          "&station=" +
          this.data.station
      );
      console.log("data", response.data.data[0]);
      this.list1 = response.data.data[0];
      // this.list1.statusDone = response.data.data[0].statusDone || "0";
      for (let j in this.list1) {
        this.list1[j].count = parseInt(j) + 1;
      }
    },
    async getAllReportDrugclues() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/allDrugrelatedReport?group=` +
          this.data.area +
          "&province=" +
          this.data.province +
          "&station=" +
          this.data.station
      );
      console.log("allDrugrelatedReport", response.data.data[0]);
      this.list2 = response.data.data[0];
      // this.list2.statusDone = response.data.data[0].statusDone || "0";
      for (let j in this.list2) {
        this.list2[j].count = parseInt(j) + 1;
      }
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>